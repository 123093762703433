
import React, {useState} from "react";

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';


import  Balert from './Alert'


import '../newExpense/expenseForm.css'


 

const ExpenseForm = (props)=>{

    const [open, setOpen] = React.useState(true);

    const [enteredTitle, setEnteredTitle] = useState('')
    const [enteredAmount, setAmount] = useState(0)
    const [enteredDate, setEnteredDate] = useState('')

    const titleChangeHandler = (event)=>{
       setEnteredTitle(event.target.value)
       
    }


    const AmountChangeHandler = (event)=>{
        setAmount(event.target.value)
    }


    const dateChangeHandler = (event)=>{
       setEnteredDate(event.target.value)
    }

    const submitHandler = (event)=>{
        event.preventDefault()

        if(enteredTitle === "" ||  enteredAmount === "" || enteredDate === ""){
          
          setOpen(true)
           
            document.getElementById("alert-pop").style.display = "block"
          return false
           
        
        }
       const expenseData = {
        title: enteredTitle,
        amount: +enteredAmount,
        date: new Date(enteredDate)
       }
    //    console.log(expenseData)
        props.onSaveExpenseData(expenseData)
       setEnteredTitle('')
       setEnteredDate('')
       setAmount(0)
    }

    
    return <form onSubmit={submitHandler}>


        <div id="alert-pop" style={{display:"none"}} onClose={() => {this.style.display="none"}}>
        <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
         Please fill all of the field
        </Alert>
      </Collapse>
     
    </Box>
        </div>
        <div className="new-expense__controls">

        
            
        <div className="new-expense__control">
            
            <label>Title</label>
            <input type="text" value={enteredTitle} onChange={titleChangeHandler}/>
            </div>

            <div className="new-expense__control">
            <label>Amount</label>
            <span className="currencyinput "><input type="number" value={enteredAmount} onChange={AmountChangeHandler} min="0.01" step="0.01"/></span>
            </div>

            <div className="new-expense__control">
            <label>Date</label>
            <input type="date" value={enteredDate} onChange={dateChangeHandler}/>
            </div>
            <div className="col-12">
              <div className="row">

              
           
            <div className="new-expense__actions col-6">
            <button type="submit">Submit</button>
            </div>

            <div className="new-expense__actions col-6 ">
            <button onClick={props.checkFormDisplay} className="btn btn-danger"  type="button">Cancel</button>
            </div>
            </div>
            </div>
        </div>
    </form>
}

export default ExpenseForm