import './ExpenseDate.css'



function ExpenseDate (props){
    const parseDate = new Date(props.date)
    const month = parseDate.toLocaleString('en-US',{month: 'long'})
    const day = parseDate.getDate()
    const year = parseDate.getFullYear()

    return (
        <div className='expense-date'>
            <div className='expense-date__day'>{day}</div>
            <div className='expense-date__month'>{month}</div>
            <div className='expense-date__year'>{year}</div>

        </div>
    )

}

export default ExpenseDate