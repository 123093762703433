import React from "react";

import ExpenseItem from "./ExpenseItem";
import './expenseList.css'


const ExpensesList = props =>{

    let expenseContent = <p className='expense__no-expense'>No Record Found</p>

    if(props.items.length === 0){
      return <h2 className="expenses-list__fallback">Found no Expenses❌</h2>
    }

    return <ul className="expenses-list">
            { props.items.map((el,key)=>(
           
           <ExpenseItem key ={key}
            title={el.title}
            amount={el.amount}
            date={el.date}
            id={el.id}
            deleteExp = {props.delExp}
            editExp = {props.editExpense}
          />
          ))}
    </ul>

}

export default ExpensesList