
import React, {Component, useState} from "react";

import ExpenseForm from "./ExpenseForm"

import '../newExpense/newExpense.css'

const NewExpense = (props)=>{

    const [expenseFormDiv, setExpenseFormDiv] = useState(false)

    const onSaveExpenseDataHandler = (enteredExpenseData)=>{
       /*  const expenseData = {
            ...enteredExpenseData,
            id: Math.round(Math.random(1,10)*10)
        }; */
       
        props.onAddExpense(enteredExpenseData)
    }
  
    const showExpenseForm = ()=>{
        setExpenseFormDiv(true)
    }

    const hideExpenseForm = ()=>{
        setExpenseFormDiv(false)
    }


       
   
      


    return <div className="new-expense">
      
            { !expenseFormDiv ? <button type="button" onClick={showExpenseForm}>Add Expense</button> : ''}
         
           
            {expenseFormDiv ? <ExpenseForm checkFormDisplay={hideExpenseForm}  onSaveExpenseData = {onSaveExpenseDataHandler}/> : ''}
        
    </div>
}


export default NewExpense