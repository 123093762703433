import React from "react";

import ChartBar from './ChartBar'

import './chart.css'

const Chart =props =>{
    const dataPointValues = props.dataPoint.map(dataPoint => dataPoint.value)
    const totalMaximum = Math.max(...dataPointValues)
    return <div className="chart">
        {props.dataPoint.map((el)=> 
        <ChartBar 
        key={el.key}
        value={el.value} 
        max={totalMaximum} 
        label={el.label} 
        />)}
        
    </div>
}

export default Chart