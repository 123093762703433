import React, { useState } from 'react';

import ExpenseDate from "./ExpenseDate";

import "../expense/expense.css"





function ExpenseItem(props){
const [showAction, setShowAction] = useState(true)

const deleteHandler = (event,id)=>{
if(window.confirm("Do you want to delete the expense?")){
    props.deleteExp(id)
}
setShowAction(true)
}

const editHandler = (event,id)=>{
    props.editExp(id)
    setShowAction(true)
}

const actions = <div className='actions'><div className='expense-item__delete' onClick={event => deleteHandler(event, props.id)} >Delete</div>
<div className='expense-item__edit' onClick={event => editHandler(event, props.id)} >Edit</div>
<div className='expense-item__close' onClick={()=> setShowAction(true)} >X</div>
</div>

// console.log(props)
  
    return(
           <li>
        <card className="expense-item">
       
        <ExpenseDate date={props.date}/>
        <div className="expense-item__description">
            <h2>{props.title}</h2>
            
            <div className="expense-item__price">₹{props.amount}</div>

            {!showAction ? actions : <div className='expense-item__action' onClick={()=> setShowAction(false)} >Action</div>}
          
            </div>

       
     
       </card>
       </li>
    )
}

export default ExpenseItem;