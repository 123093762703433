import React, {useState} from "react";

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';


import  Balert from './Alert'

import 'bootstrap/dist/css/bootstrap.css';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.


 

const ExpenseForm = (props)=>{

    

    const [open, setOpen] = React.useState(true);


    const [enteredID, setEnteredID] = useState(props.editThis[0].id)

    const [enteredTitle, setEnteredTitle] = useState(props.editThis[0].title)
    const [enteredAmount, setAmount] = useState(props.editThis[0].amount)
    const [enteredDate, setEnteredDate] = useState(()=>{
        const date = new Date(props.editThis[0].date)
        const month = (date.getMonth() + 1 <= 10 ? '0'+(date.getMonth() + 1) : date.getMonth() + 1)
      
      return(date.getFullYear() + "-" + month + "-" + date.getDate())

     
    })

    


    const titleChangeHandler = (event)=>{
       setEnteredTitle(event.target.value)
       
    }


    const AmountChangeHandler = (event)=>{
        setAmount(event.target.value)
    }


    const dateChangeHandler = (event)=>{
       setEnteredDate(event.target.value)
    }

    const submitHandler = (event)=>{
        event.preventDefault()

        if(enteredTitle === "" ||  enteredAmount === "" || enteredDate === ""){
          
          setOpen(true)
           
            document.getElementById("alert-pop").style.display = "block"
          return false
           
        
        }
       const expenseData = {
        title: enteredTitle,
        amount: +enteredAmount,
        date: new Date(enteredDate),
        id: enteredID
       }
    //    console.log(expenseData)
        props.onEditAddExpense(expenseData)
        props.afterSubmit()
    }

    
    return <form onSubmit={submitHandler}>


        <div id="alert-pop" style={{display:"none"}} onClose={() => {this.style.display="none"}}>
        <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
         Please fill all of the field
        </Alert>
      </Collapse>
     
    </Box>
        </div>
        <div className="new-expense__controls">

        
     
            <div class="form-group col-12">
            <label for="">Title</label>
            <input type="text" className="form-control" value={enteredTitle} onChange={titleChangeHandler}/>
            </div>

            <div class="form-group col-12">
            <label for="">Amount</label><br/>
            <div class="input-group">
            <span class="input-group-text">$</span>
            <input type="number"  className="form-control" value={enteredAmount} onChange={AmountChangeHandler} min="0.01" step="0.01"/>
           </div> 
           </div>

            <div class="form-group col-12">
            <label for="">Date</label>
            <input type="date" className="form-control" value={enteredDate} onChange={dateChangeHandler}/>
            </div>
            
            
            <div className="form-group col-12 text-center">
            <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </div>
    </form>
}

export default ExpenseForm