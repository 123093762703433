import React, { useEffect, useState } from 'react';


import Card from '../UI/Card';
import ExpensesFilter from './ExpenseFilter';
import './expense.css';
import ExpensesList from './ExpensesList'

import ExpenseChart from './ExpenseChart';



const Expenses = (props) => {
  const [filteredYear, setFilteredYear] = useState('All');



  const filterChangeHandler = selectedYear => {
    setFilteredYear(selectedYear);
   
   
  }; 

  const filteredExpense = props.items.filter((el)=>{

    const parseDate = new Date(el.date)
     
    if(filteredYear !== 'All'){
       return (parseDate.getFullYear() ==  filteredYear)
     
    }else{
      return el
    }
   
  })





  return (
    <div>
      < Card className='expenses'>
        <ExpensesFilter selected={filteredYear} onChangeFilter={filterChangeHandler} />
        <ExpenseChart expenses= {filteredExpense}/>
      <ExpensesList items = {filteredExpense} delExp  = {props.delExpense}  editExpense = {props.edExp}/>    
      </Card>
    </div>
  );
};

export default Expenses;


