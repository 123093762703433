import React from "react";

import Chart from '../chart/Chart'

const ExpenseChart = props =>{

    const chartDataPoints = [
            { label: 'Jan', value: 0 },
            { label: 'Feb', value: 0 },
            { label: 'Mar', value: 0 },
            { label: 'Apr', value: 0 },
            { label: 'May', value: 0 },
            { label: 'Jun', value: 0 },
            { label: 'Jul', value: 0 },
            { label: 'Aug', value: 0 },
            { label: 'Sep', value: 0 },
            { label: 'Oct', value: 0 },
            { label: 'Nov', value: 0 },
            { label: 'Dec', value: 0 },


    ]
  

    for( const expense of props.expenses){

     
        
        const parseDate = new Date(expense.date)
        const expenseMonth = parseDate.getMonth(); // starting at 0 => January as 0 and december as 11
        chartDataPoints[expenseMonth].value += expense.amount
    }

    // console.log(chartDataPoints)



    return <Chart dataPoint={chartDataPoints}/>
}

export default ExpenseChart