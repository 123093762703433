import React, { useState } from 'react';

import logo from './logo.svg';


import NewExpense, {showExpenseForm} from './components/newExpense/NewExpense';

import Expense , {filterChangeHandler} from './components/expense/Expense'

import Form from './components/newExpense/ModalForm'

import '../src/components/UI/modal.css'

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';


const DUMMY_EXPENSES  = [
  {id:1,title: 'Car Insurance', amount:120.00, date: new Date(2022,10,12)},
  {id:2,title: 'Marketing', amount:190.00, date: new Date(2022,1,14)},
  {id:3,title: 'Ticket', amount:890.00, date: new Date(2021,7,19)},
  {id:4,title: 'Eating', amount:345.00, date: new Date(2020,5,23)},
  
]






function App() {


  const [openPop, setOpenPop] = React.useState(true);
  const [icon, setIcon] = React.useState('warning');
  const [editExp, setEditExp] = React.useState('');
  const [showEditPop, setshowEditPop] = React.useState('none');




const [expense, setExpenses] = useState(()=>{
  const storedExpense = localStorage.getItem("storedExpense") || 0 

if(!storedExpense){
  const exp = JSON.stringify(DUMMY_EXPENSES)

  localStorage.setItem("storedExpense", exp)
}

const allExp =   JSON.parse(localStorage.getItem("storedExpense"))

return allExp
})


const checkLimit = (prevExp,expense) =>{
  let sum = 0
  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
   prevExp.forEach(el =>{
      const parseDate = new Date(el.date)
      if((parseDate.getMonth()) + 1 ===  (expense.date.getMonth() + 1) && (parseDate.getYear()) ===  (expense.date.getYear())){
        // console.log(el)
          sum += parseInt(el.amount) + expense.amount
        
      }
  })

  console.log(sum)

  if(sum > 10000){ // cap of 3k is here
    document.getElementById("alertHuge").style.display = "block"
    document.getElementById("monthExpWarning").innerHTML = `Your Monthly Expense for ${month[expense.date.getMonth()]} is more than ₹10000.😒`
    
 
  }

}


const addExpenseHandler = (expense)=>{

  // expenses.push(expense)

  setExpenses( prevExpense => {
    
    checkLimit(prevExpense,expense)


    const allIds = prevExpense.map(el=>{
      return el.id
      })

    const nextId = (Math.max(...allIds) + 1)

      const expenseData = {
            ...expense,
            id: nextId
        };

    const stringAdded = [expenseData,...prevExpense]
    const addedExp = JSON.stringify(stringAdded)
    localStorage.setItem("storedExpense", addedExp)
   return [expenseData,...prevExpense]
   
  })
  document.body.scrollTop = document.documentElement.scrollTop = 0;

  setIcon('success')
  document.getElementById("alert").style.display = "block"
  document.getElementById("alerts").innerHTML = "Your Expense has been Added!"

}

const editExpenseHandler = (editExpense)=>{

  setExpenses( prevExpense => {
    const newExp = prevExpense.filter(el=>{
      if(el.id === editExpense.id){
        el.title = editExpense.title
        el.amount = editExpense.amount
        el.date = editExpense.date
      }
      return el
    })
    const addedExp = JSON.stringify(newExp)
    localStorage.setItem("storedExpense", addedExp)
   return newExp
  })

  document.body.scrollTop = document.documentElement.scrollTop = 0;

  setIcon('info')
  document.getElementById("alert").style.display = "block"
  document.getElementById("alerts").innerHTML = "Your Expense has been Edited!"
  }

 




const deleteExpense = id =>{
  const afterDelExpense = expense.filter(el => {
    return el.id != id
  })
  setExpenses( prevExpense => {
    const addedExp = JSON.stringify(afterDelExpense)
    localStorage.setItem("storedExpense", addedExp)
   return afterDelExpense
  })

  setIcon('error')
  document.getElementById("alert").style.display = "block"
  document.getElementById("alerts").innerHTML = "Your Expense has been Deleted!"
 
}



const editExpenseByID = id =>{
  const whichToEdit = expense.filter(el=>{
      return el.id == id
  })
  setshowEditPop('block')
  setEditExp(whichToEdit)

  
  
}

const hidePopForm =()=>{
  setshowEditPop('none')
}





  setTimeout(()=>{
    document.getElementById("alert").style.display = "none"
    document.getElementById("alertHuge").style.display = "none"

  },5000)




 
  return (
  
      <div>
        
       <card className='expenses'>
        <h1 className='heading'>Expense List</h1>

        <div id="alert"  onClose={() => {this.style.display="none"}} >
        <Box sx={{ width: '100%' }}>
      <Collapse in={openPop}>
        <Alert severity={icon}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenPop(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
         <span id="alerts">Please delete the dummy expenses before inserting your own Expenses!😊</span>
        </Alert>
      </Collapse>
     
    </Box>
        </div>

        <div id="alertHuge"  onClose={() => {this.style.display="none"}} style={{display:"none"}}>
        <Box sx={{ width: '100%' }}>
      <Collapse in={openPop}>
        <Alert severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenPop(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
         <span id='monthExpWarning'></span>
        </Alert>
      </Collapse>
     
    </Box>
        </div>

           
{showEditPop  != 'none' ? <div class="modal fade show" id="exampleModalLive" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" style={{display:showEditPop}}>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit Your Expense</h5>
        <button type="button" class="close btn btn-danger" data-dismiss="modal" onClick={hidePopForm} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <Form editThis = {editExp} onEditAddExpense ={editExpenseHandler} afterSubmit = {hidePopForm}/>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={hidePopForm}>Close</button>
        
      </div>
    </div>
  </div>
</div> : ''}        


        <NewExpense onAddExpense ={addExpenseHandler}  />
     
        <Expense items={expense} delExpense={deleteExpense} edExp = {editExpenseByID}/>
       
        </card>
        </div>

      
  );
}

export default App;
